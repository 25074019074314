import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'crmTask';

const crmTaskService = {
  getTaskById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/task/${id}`,
    });
  },

  getTasks: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/tasks`,
      data,
    });
  },

  createTask: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateTask: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteTask: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  uploadFile: (files, id, userId) => {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    formData.append('userId', userId);

    return request({
      method: Methods.POST,
      resource: `${baseURL}/uploadFile/${id}`,
      formData: true,
      data: formData,
    });
  },

  deleteFile: (id, userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteFile/${id}`,
      data: { userId },
    });
  },

  getContactMaps: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/contactMaps`,
      data,
    });
  },

  getParent: (parentId) => {
    return request({
      resource: `${baseURL}/parent/${parentId}`,
    });
  },
};

export default crmTaskService;
